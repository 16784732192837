<template>
  <section class="page">
    <div
      class="row d-flex justify-content-center align-items-center mb-1 mr-1  "
    >
      <h5 class="col-md-11">
        MAPA EPIDEMIOLÓGICO COVID-19
      </h5>
      <router-link to="/dashboard">
        <b-button variant="primary">
          Gráficos
        </b-button>
      </router-link>
    </div>
    <city-incidents ref="incidents2" />
  </section>
</template>

<script>
import CityIncidents from "../components/Map/CityIncidents";
export default {
  components: {
    CityIncidents
  }
};
</script>
<style lang="scss" scoped>
.card {
  border-radius: 10px;
  border-width: 0px;
}
h5 {
  text-transform: uppercase;
}
#floating {
  padding: 0 10px 0 10px;
  background: #132644;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 5px;
  z-index: 1000 !important;
  position: absolute;
  top: 95px;
  right: 45px;
}
</style>
