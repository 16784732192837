<template>
  <div id="map2"></div>
</template>
<script>
import L from "leaflet";
import Service from "../../services";
import ce_state from "./ce_state";
export default {
  data() {
    return {
      map: null,
      geoLayer: null,
      stLayer: null,
      circleLayer: null,
      highlight: {
        fillOpacity: 0.1,
        weight: 2,
        opacity: 1,
        color: "black"
      },
      confirmed: {
        fillColor: "gray",
        fillOpacity: 0.2,
        weight: 2,
        opacity: 1,
        color: "gray",
        dashArray: "0"
      },
      default: {
        fillColor: "gray",
        fillOpacity: 0.1,
        weight: 0.5,
        opacity: 1,
        color: "gray",
        dashArray: "0"
      }
    };
  },
  mounted() {
    this.populate();
  },
  methods: {
    async populate() {
      //Initiate the map and geoLayers
      this.map = L.map("map2").setView([-5.2351036, -39.3740503], 7);
      new L.tileLayer("http://{s}.tile.osm.org/{z}/{x}/{y}.png", {
        attribution:
          '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      }).addTo(this.map);
      this.geoLayer = L.geoJson(null, {
        onEachFeature: this.forEachFeature,
        style: this.style
      });
      this.stLayer = L.geoJson(null, {
        style: () => {
          return {
            color: "#088FE4",
            fillOpacity: 0.1,
            dashArray: "0"
          };
        }
      });

      this.stLayer.addTo(this.map);
      this.geoLayer.addTo(this.map);
      this.stLayer.setZIndex(50);
      this.geoLayer.setZIndex(51);

      this.circleLayer = L.layerGroup().addTo(this.map);
      var legend = L.control({ position: "bottomright" });
      legend.onAdd = () => {
        let div = L.DomUtil.create("div", "info-legend"),
          labels = ["<strong>Legenda</strong>"],
          categories = [
            "Municípios com casos confirmados",
            "Municípios com casos suspeitos"
          ];

        for (var i = 0; i < categories.length; i++) {
          div.innerHTML += labels.push(
            '<i style="background:' +
              this.getColor(categories[i]) +
              '">&nbsp;&nbsp;&nbsp;</i> ' +
              (categories[i] ? categories[i] : "+")
          );
        }

        div.innerHTML = labels.join("<br>");
        return div;
      };

      legend.addTo(this.map);
      //Request for data
      const service = new Service();

      this.stLayer.addData(JSON.parse(ce_state));
      let res = await service.get("statistics/geocities");
      const datas = res.map(d => {
        if (d.last_update.split("-").length > 1) {
          const [year, month, day] = d.last_update.split(" ")[0].split("-", 3);
          d.last_update = `${day}/${month}/${year} - ${
            d.last_update.split(" ")[1]
          }`;
        }
        return {
          type: "Feature",
          properties: {
            confirmed:
              Number(d.c_qty_isolated) +
              Number(d.c_qty_interned) +
              Number(d.c_qty_lethal) +
              Number(d.c_qty_recovered),
            c_isolated: d.c_qty_isolated,
            c_interned: d.c_qty_interned,
            c_recovered: d.c_qty_recovered,
            c_lethal: d.c_qty_lethal,

            suspicious: Number(d.s_qty_isolated) + Number(d.s_qty_interned),
            s_isolated: d.s_qty_isolated,
            s_interned: d.s_qty_interned,

            discarded: d.qty_discarded,

            l_tax: (
              (Number(d.c_qty_lethal) /
                (Number(d.c_qty_isolated) +
                  Number(d.c_qty_interned) +
                  Number(d.c_qty_lethal) +
                  Number(d.c_qty_recovered))) *
                100 || 0
            ).toFixed(2),

            available_beds: d.available_beds,
            available_uti: d.available_uti,
            available_breather: d.occupied_beds,
            last_update: d.last_update,
            id: d.city_id,
            name: d.name
          },
          geometry: JSON.parse(d.geometry)
        };
      });
      this.geoLayer.addData({ features: datas });
      this.populateCities();
    },
    style(e) {
      return Number(e.properties.confirmed) > 0 ? this.confirmed : this.default;
    },
    getColor(e) {
      return e === "Municípios com casos confirmados" ? "#808080" : "#a6a6a6";
    },
    forEachFeature(feature, layer) {
      const data = feature.properties;

      const popupContent = `<p>
          <h5 style="text-align: center">${data.name}</h5>

          <h6 style="text-align: center">BOLETIM COVID-19</h6>
          <b style="color: red">Confirmados:</b> ${data.confirmed}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;• <b>Isolados:</b> ${data.c_isolated}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;• <b>Internados:</b> ${data.c_interned}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;• <b>Curados:</b> ${data.c_recovered}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;• <b>Óbitos:</b> ${data.c_lethal}<br/>
          <b style="color: #999900">Suspeitos:</b> ${data.suspicious}<br/>
          <b style="color: gray">Descartados:</b> ${data.discarded}<br/>
          <b style="color: black">Letalidade:</b> ${data.l_tax}%<br/><br/>
          <b>Leitos enfermaria:</b> ${data.available_beds}<br/>
          <b>Leitos UTI's:</b> ${data.available_uti}<br/>

          <b>Respiradores no município:</b> ${data.available_breather}<br/>
          <b>Atualizado em</b> ${data.last_update}<br/>
          ${
            data.last_update != "sem atualização"
              ? "pela Secretaria Municipal de Saúde"
              : ""
          }<br/>
          
        </p>
        <div style="width: 100%; display: flex; justify-content: center;">
          <a class="see-more" href="/#/cidade/${
            feature.properties.id
          }">Ver Mais</a>
        </div>
        `;

      let popup = L.popup();
      popup.setContent(popupContent);
      layer.bindPopup(popup);

      layer.on("click", e => {
        let pop = e.target.getPopup();
        pop.setLatLng(e.target.getBounds().getCenter());
        e.target.openPopup();
        this.geoLayer.setStyle(this.style);
        layer.setStyle(this.highlight);
        this.zoomToFeature(e);
      });
    },
    populateCities() {
      const request = new Service();
      request.get(`statistics/district`).then(res => {
        const resO = res.sort((a, b) => {
          const totalA =
            (Number(a.s_qty_isolated) + Number(a.s_qty_interned)) * 20 +
            (Number(a.c_qty_isolated) +
              Number(a.c_qty_interned) +
              Number(a.c_qty_lethal) +
              Number(a.c_qty_recovered)) *
              30;
          const totalB =
            (Number(b.s_qty_isolated) + Number(b.s_qty_interned)) * 20 +
            (Number(b.c_qty_isolated) +
              Number(b.c_qty_interned) +
              Number(b.c_qty_lethal) +
              Number(b.c_qty_recovered)) *
              30;
          return totalA > totalB ? -1 : totalB > totalA ? 1 : 0;
        });
        resO.forEach(e => {
          const qty_cases =
            (Number(e.s_qty_isolated) + Number(e.s_qty_interned)) * 20 +
            (Number(e.c_qty_isolated) +
              Number(e.c_qty_interned) +
              Number(e.c_qty_lethal) +
              Number(e.c_qty_recovered)) *
              30;

          let severity = 1;

          if (qty_cases <= 500) {
            severity = 1;
          } else if (qty_cases <= 5000) {
            severity = 2;
          } else if (qty_cases <= 10000) {
            severity = 3;
          } else {
            severity = 4;
          }

          L.circle([Number(e.latitude), Number(e.longitude)], {
            radius: severity * 200,
            color: ["#ff000080", "#ff0000AA", "#ff0000CC", "#ff0000FF"][
              severity - 1
            ]
          }).addTo(this.circleLayer).bindPopup(`
            <h3>${e.district}</h3>
            <hr/>
           <b style="color: red">Confirmados:</b> ${Number(e.c_qty_isolated) +
             Number(e.c_qty_interned) +
             Number(e.c_qty_lethal) +
             Number(e.c_qty_recovered)}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;• <b>Isolados:</b> ${e.c_qty_isolated}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;• <b>Internados:</b> ${e.c_qty_interned}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;• <b>Curados:</b> ${e.c_qty_recovered}<br/>
          &nbsp;&nbsp;&nbsp;&nbsp;• <b>Óbitos:</b> ${e.c_qty_lethal}<br/>
          <b style="color: #999900">Suspeitos:</b> ${Number(e.s_qty_isolated) +
            Number(e.s_qty_interned)}<br/>
          <b style="color: gray">Descartados:</b> ${e.qty_discarded}
          `);
        });
      });
    },
    zoomToFeature(e) {
      this.map.fitBounds(e.target.getBounds());
    }
  }
};
</script>
<style lang="scss">
.info-legend {
  background: #f3f3f3;
  padding: 3px 4px;
  border: 1px solid #999;
}
.see-more {
  background: #fff;
  color: #0d80ac !important;
  text-decoration: none;
  font-weight: bold;
  border-radius: 4px;
  width: 100%;
  padding: 10px 0px;
  text-align: center;
  border: 1px solid #0d80ac;
}

.see-more:hover {
  text-decoration: none;
  color: #fff !important;
  background: #0d80ac;
}
#map2 {
  min-height: calc(100vh - 180px);
  width: 100%;
}
</style>
